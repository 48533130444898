#annonce {

    .pictures {

        height: 300px;
        margin-bottom: 30px;
    }

    .picture {

        height: 300px;
        position: relative;
        margin-bottom : 30px;

        img {

            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .categorie {

            position: absolute;
            top : 10px;
            left : 10px;
            border-radius: 3px;
            padding : 0 5px;
            @apply bg-peel text-white;
        }

        .don-tag {

            background-color : #96E0AF;
            margin-top : 10px;
            color : #000;
            width : fit-content;
            position: absolute;
            top : 30px;
            left : 10px;
            border-radius: 3px;
            padding : 0 5px;

            &::before {

                content: '';
                display: inline-block;
                width: 21px;
                height: 17px;
                margin-right: 10px;
                vertical-align: -3px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='21.822' height='15.569' viewBox='0 0 21.822 15.569'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_258' data-name='Rectangle 258' width='21.822' height='15.569' transform='translate(-3)'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Groupe_798' data-name='Groupe 798' transform='translate(3)' clip-path='url(%23clip-path)'%3E%3Cpath id='Tracé_473' data-name='Tracé 473' d='M31.237,6.024c.232.183.446.351.581.464a.037.037,0,0,0,.049,0c.136-.113.35-.282.585-.467,1.124-.885,2.823-2.223,3.1-3.54a2.047,2.047,0,0,0,.045-.424A2.111,2.111,0,0,0,35.159.776a1.968,1.968,0,0,0-2.566-.5L32.548.3a2,2,0,0,0-.459.392l-.245.284L31.6.691a1.972,1.972,0,0,0-3.069.086,2.109,2.109,0,0,0-.443,1.285,2.033,2.033,0,0,0,.045.424c.281,1.316,1.98,2.654,3.1,3.539' transform='translate(-21.543 1.412)'/%3E%3Cpath id='Tracé_474' data-name='Tracé 474' d='M50.513,31.528a1.61,1.61,0,0,1,.067.182l2.9-3.122c-.015-.013-.029-.028-.044-.041a1,1,0,0,0-.713-.212,1.172,1.172,0,0,0-.721.336l-2.25,2.02a1.876,1.876,0,0,1,.764.836' transform='translate(-37.294 -20.99)'/%3E%3Cpath id='Tracé_475' data-name='Tracé 475' d='M18.711,28.621a1.1,1.1,0,0,0-1.538.094l-3.709,4a1.688,1.688,0,0,1-1.834.94L7.772,32.73l.15-.63,3.859.921a1.057,1.057,0,0,0,1.128-.64.773.773,0,0,0-.014-.639,1.3,1.3,0,0,0-.68-.634L12.2,31.1h0l-.182-.069c-.582-.22-.746-.281-1.338-.5-.748-.273-1.4-.5-1.98-.692-3.817-1.261-3.723-1.038-6.92-.1-.174.051-.248.1-.7.232v4.214l3.833.3.077.034c.583.259,2.549,1.134,3.393,1.528A4.274,4.274,0,0,0,9.7,36.5a2.447,2.447,0,0,0,1.357-.286c1.54-.731,4.2-2.114,4.5-2.269l.013-.006,3.256-3.765a1.1,1.1,0,0,0-.1-1.541' transform='translate(-0.269 -20.942)'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
            }

        }

    }

    h1 {

        font-size: 30px;
        font-weight: 500;
    }

    h2 {

        font-size: 25px;
        font-weight: 500;

    }

    .description,
    .date {

        /* color : #9A9A9A; */
        color: #736e6e;

    }

    .section-title {

        margin-top : 40px;

    }

    .description {

        /* font-size : 14px; */
    }

    .date {

        font-size : 12px;
    }

    .interraction {
        display: flex;
        flex-flow: row nowrap;

        svg:first-of-type {

            margin-right: 10px;
            margin-bottom: 10px;

        }
    }
    .whislist {

        cursor: pointer;

        &.active, &:hover {

            #Rectangle_199 {

                fill: #F89900;
            }

        }
    }

    .share {

        cursor: pointer;

        &:hover {

            #Rectangle_299 {

                fill: #F89900;
            }

            #Trace_479 {

                stroke: #fff;

            }
        }
    }

    #user-card {

        min-width: 191px;
        width: fit-content;

        svg.icon {

            width: 56px;

        }

        #info-user {

            padding-left: 10px;
            padding-right: 10px;
            width: 100%;

        }

        .count {

            margin-top: -5px;
            font-size: 12px;
        }

        .btn {

            align-items : center;
            justify-content : center;
            display : flex;
            color : #fff;
            border-radius: 3px;
            height: 37px;
            margin-top: 10px;
            width: 100%;
        }
        &:hover {
            a svg {
                transform: translateX(5px);
            }
        }
    }

    #map-area {

        border-top: 1px solid rgba(0,0,0,0.10);
        border-bottom: 1px solid rgba(0,0,0,0.10);
        margin-bottom: 30px;
        margin-top: 50px;

        h2 {

            margin-top: 40px;
        }
    }

    #annonce-meta {

        /* font-weight: 500; */
        margin-top: 20px;
        margin-bottom: 28px;

        .prices {

            margin-bottom: 5px;
        }

        .etat {

            font-weight: bold;
        }
    }

    .encart {

        background-image: url('/images/bg-encart.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 3px;
        padding: 25px 34px;
        font-weight: 500;
    }

    .avatar {

        width: 33px;
        height: 33px;
        border-radius: 50%;
    }

}

#share-area {

    display: flex;
    justify-content: space-between;

    &.modal-body {
        width: 350px;
    }

    a {

        width: 62px;
        height: 62px;
        display: inline-block;
    }

    svg:hover {

        fill: #F89900;
    }
}

