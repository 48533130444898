#top-header {


    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;

    @screen lg {
        min-height: 533px;
    }

    &::before {

        content: '';
        height: 100%;
        width: 100%;
        position: absolute;
        top : 0;
        background-color: white;

        @screen md {
            background-color: rgba(255,255, 255, .9);
        }

        @screen lg {
            background-color: rgba(0,0,0,0.20);
        }
    }

    @apply relative;

    h1 {

        font-size: 35px;
        text-align: center;
        font-weight: bold;
        /* margin-bottom: 25px; */
        z-index : 1;
        @apply pb-8 lg:pb-0;
        @apply mb-0 lg:mb-6;
        @apply text-[26px] lg:text-[35px];
        @apply text-black lg:text-white;
    }

}
