#box-card,
#box-annonce {

    .title {

        color: #000;
        font-size: 25px;
        font-weight: 500;
    }

    .back {

        color: #000;
        font-weight: bold;
        font-size: 14px;

        &::before {

            content: '';
            display: inline-block;
            width: 21px;
            height: 17px;
            margin-right: 10px;
            vertical-align: middle;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.201' height='17.201' viewBox='0 0 17.201 17.201'%3E%3Cg transform='translate(8.634) rotate(45)'%3E%3Cpath d='M483.793,239.26l-7.939,8.032,1.623,1.623,7.939-8.032V248.8h2.549l0-12.1-12.1,0v2.55Z' transform='translate(-475.854 -236.706)'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            transform: rotate(180deg);
        }

    }

    .btn.bg-peel {

        border-radius: 3px;
        color: #fff;
        padding: 7px 42px 9px 42px;

        &:hover {
            background-color: #E88F00;
        }
    }

    .error {

        color: #E73935;
        font-size: 14px;

    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    textarea {

        @apply appearance-none focus:outline-none;

    }

    .checkradio-label {

        display: inline-block;
        font-weight: 400;

        input {
            display: inline-block;
        }
    }
    /* label[for="remember_me"],
    #labeldon,
    .checkradio-label {

        position: relative;
        padding-left: 25px;

        input {
            position: absolute;
            left: 0;
            top: 0;
        }
    } */
    #remember_me::before {
        width: 18px;
        height: 18px;
        margin-left: -4px;
        margin-top: -3px;
    }
    #acceptance::before,
    #don::before {
        margin-left: -2px;
        margin-top: -2px;
    }
    #immediatement::before {
        margin-left: -1px;
        margin-top: -1px;
    }
    #cgv::before,
    #info-bam::before {
        margin-left: -4px;
        margin-top: -3px;
    }

    #deconstruction {
        &::before {
            margin-left: 0;
            margin-top: 0;
        }
    }
}
