.no-header {

    padding-top: 116px;

}

#app-section {

    min-height: calc(100vh - 313px);
}

.notice {

    main {

        min-height: calc(100vh - 313px);
        display: flex;
        align-items: center;

    }
}
