
#dashboard,
#informations-personnelles,
#mes-alertes,
#mes-annonces,
#mes-favoris {

    margin-bottom: 40px;
    padding-right: 0;
    padding-left: 0;

    #box-card > div {
        box-shadow: 0px 0px 20px #0000001A;
    }

    input[type='text']:focus-within ~ label,
    input[type='number']:focus-within ~ label,
    input[type='email']:focus-within ~ label,
    input[type='password']:focus-within ~ label,
    input[type='password']:not(:placeholder-shown) ~ label,
    input[type='email']:not(:placeholder-shown) ~ label,
    input[type='text']:not(:placeholder-shown) ~ label,
    input[type='number']:not(:placeholder-shown) ~ label {
        @apply text-peel transform scale-75 -translate-y-5 !important;
    }
    select {
        width: 100%;
        height: 37px;
        border: 1px solid rgba(188, 188, 188, .3);
        border-radius: 3px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3E%3Cpath d='M7 8 0 0h14Z' fill='%23f89900'/%3E%3C/svg%3E") no-repeat right transparent;
        -webkit-appearance: none;
        background-position: calc(100% - 15px) center;
        background-size: 14px 8px;
        padding-left: 15px;
    }
    #box-card [type="checkbox"] ~ label {
        vertical-align: top;
    }
}

#dashboard {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    .card {
        @apply flex flex-col justify-between bg-white shadow-md sm:rounded-md;
        padding: 35px 20px 20px 20px;
        min-height: 210px;

        &:hover {
            box-shadow: 0px 0px 20px #00000033;
        }
    }
    .col-background {
        background-size: cover;
        padding: 27px 35px;
        height: auto;
    }
    #picto-coeur {
        background-size: contain;
        background-repeat: no-repeat;
        width: 75px;
        height: 75px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 85.172 76.631'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 297' fill='%23f89900' d='M0 0h85.172v76.631H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Groupe 961' clip-path='url(%23a)'%3E%3Cpath data-name='Tracé 524' d='M77.7 41.356A23.968 23.968 0 0 0 43.5 7.782c-.372.349-.662.725-.985 1.089-.235-.249-.458-.509-.684-.743A23.96 23.96 0 0 0 7.32 41.367l33.652 33.715a2.157 2.157 0 0 0 3.05 0Z' fill='%23f89900'/%3E%3C/g%3E%3C/svg%3E");
    }
}

#informations-personnelles {
    #box-card {
        min-height: unset;
    }
    #breadcrumbs {
        margin-bottom: 19px;
    }
    .profil-picture {

        img {
            width: 95px;
            height: 95px;
            object-fit: cover;
        }

        svg {
            height: 50px;
            width: 50px;
            position: absolute;
            right: 0;
            bottom: 0;
            background-color: #FFF;
            border-radius: 50%;
            box-shadow: 0px 0px 6px #00000033;
        }
    }
    .back::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 17.201 17.201'%3E%3Cpath d='m4.76 9.781 11.293-.065V7.42L4.76 7.486l5.598-5.598L8.556.085 0 8.641l8.556 8.556 1.803-1.803Z'/%3E%3C/svg%3E");
        height: 12px;
        width: 12px;
        display: inline-block;
        transform: rotate(0deg);
        transition: all .1s ease-in-out;
    }

    .back:hover::before {
        transform: rotate(0deg) translateX(-6px);
    }

    .btn.bg-peel[type="submit"] {
        padding: 7px 24px 9px 24px;
        font-size: 16px;
    }
}

/* #mes-annonces { */
    .annonce-card {
        display: grid;
        box-shadow: 0px 0px 20px #0000001A;
        border-radius: 3px;
        grid-template-columns: 1fr;

        @screen lg {
            grid-template-columns: 250px 1fr;
        }
    }
    .annonce-card-alertes {
        grid-template-columns: 1fr;

    }
    .alerte-action {
        width: 600px;
        max-width: 100%;
        display: flex;
        justify-content: space-evenly;
        margin-top: 30px;
        flex-flow: column nowrap;
        gap: .5em;
        text-align: center;

        @screen lg {
            flex-flow: row nowrap;
        }
    }
    .annonce-card-picture {
        position: relative;

        .thumbnail img {
            width: 100%;
            height: 100%;
            height: 220px;
            object-fit: cover;
            border-bottom-left-radius: 3px;
            border-top-left-radius: 3px;

            @screen lg {
                width: 250px;
            }
        }
        .tags {
            position: absolute;
            top: 0;
            width: 100%;
            padding: 12px 14px;

        }
    }
    .tag {
        font-size: 14px;
        color: #FFF;
        padding: 2px 13px;
        border-radius: 3px;
    }
    .annonce-card-body {
        display: flex;
        flex-flow: column nowrap;
        padding: 15px 24px;
        justify-content: space-between;

        .annonce-card-title {
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            line-height: 23px;
            font-weight: 500;
            color: #000;
        }
        .published,
        .ref,
        .category,
        .secteur,
        .price {
            color: #9A9A9A;
            font-size: 14px;
            font-family: 'Roboto', sans-serif;
            line-height: 18px;

            strong {
                color: #000;
            }
        }
    }
/* } */

.btn.btn-green {
    background-color: #96E0AF;
    border-radius: 3px;
    color: #FFF;
    padding: 4px 30px;
    font-size: 16px;
    line-height: 21px;

    &:hover {
        background-color: #79D096;
    }
}
.btn.btn-peel {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    padding: 7px;

    svg {
        width: 30px;
        height: 15px;
    }

    &:hover {
        background-color: #E88F00;
    }
}
.btn.btn-red {
    width: 30px;
    height: 30px;
    border-radius: 3px;
    padding: 7px;
    background-color: #E73935;

    svg {
        width: 30px;
        height: 15px;
    }

    &:hover {
        background-color: #C91E1A;
    }
}
.btn.btn-add {

    border-radius: 3px;
    padding: 7px 24px 7px 47px;
    background-color: #F89900;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20.279 20.279'%3E%3Cpath d='M10.139 0v20.279m10.14-10.139H0' fill='none' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 14px 50%;
    height: 37px;

    &:hover {
        background-color: #E88F00;
    }
}
.btn.btn-supp {
    border-radius: 3px;
    padding: 7px 20px 9px;
    background-color: #F89900;
    height: 37px;
    color: #FFF;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;

    &:hover {
        background-color: #E88F00;
    }
}

.publish,
.edit,
.delete {
    /* display: inline-block; */
    vertical-align: text-top;
    margin-right: 10px;
}

#mes-annonces {

    .no-product {
        min-height: calc(100vh - 311px);
    }
}
