.btn {

    display: inline-flex;
    align-items: center;
    font-weight: 500;
    padding: 0 10px;
    line-height: normal;

    &.bg-peel:hover {
        background-color: #E88F00;
    }

}

.cta {

    @apply bg-peel;
    height: 37px;
}

.save {
    padding: 11px;
    color : #fff;

    &::before {

        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 18px;
        height: 22px;
        margin-right: 11px;
        background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' xmlns='http://www.w3.org/2000/svg' width='17.649' height='21.991'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='none' stroke='%23fff' d='M0 0h17.649v21.991H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='1.832'%3E%3Cpath d='m14.821 13.807 1.49 3.836H1.327l1.454-3.836V6.935a6.02 6.02 0 0 1 12.04 0ZM5.387 17.643a3.432 3.432 0 1 0 6.864 0'/%3E%3C/g%3E%3C/svg%3E");
    }
}
