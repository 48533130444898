@import '@yaireo/tagify/dist/tagify.css';

a[type="submit"],
input[type="radio"],
input[type="checkbox"] {
    appearance:initial;
    -moz-appearance:initial;
    -webkit-appearance: initial;
}

label.checkradio-label,
label.checkradio-label {
    vertical-align: sub;
}
#free_product {
    vertical-align: sub;
}
.btn.btn-peel {
    display: inline-flex !important;

    svg {
        width: 30px !important;
        height: 15px !important;
    }
}


input[type="checkbox"],
input[type="radio"] {
    vertical-align: sub;
    display: inline-block;
    position: relative;
    margin-right: 10px;
    margin-left: 3px;
    height: 8px;
    width: 8px;

    &::before {

        content: '';
        display: inline-block;
        width: 18px;
        height: 18px;
        background: #fff;
        border : 2px solid #96E0AF;
        vertical-align: top;
        margin-left: -3px;
        margin-top: -3px;
    }

    &:checked::before {


        background-color: #96E0AF;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.069' height='8.734'%3E%3Cpath d='M.707 3.28l4.042 4.041L11.362.708' fill='none' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
        background-repeat : no-repeat;
        background-position: center center;

    }

}


input[type="date"]::placeholder,
input[type="date"] {
    color: #000;
}

.dropdown {

    width : 110px;
    position: relative;
    display: inline-block;
    background-color: #FAFAFA;
    position: relative;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;

    height: 38px;
    background-color: #FFF;
    border: 1px solid rgba(188, 188, 188, .3);
    border-radius: 0.375rem;

    .form-section-title {

        position: relative;

        &::after {

            content: '';
            width: 14px;
            display: inline-block;
            height: 8px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' viewBox='0 0 14 8'%3E%3Cpath d='M7,0l7,8H0Z' transform='translate(14 8) rotate(180)' fill='%23f89900'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            vertical-align: middle;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            transition: transform .15s ease-in-out;
        }
    }
    &.open .form-section-title::after {
        transform: scaleY(-1) translateY(50%);
    }

    .form-list {

        position: absolute;
        background-color: #FAFAFA;
        width: 100%;
        left:0;
        display: none;
        z-index: 2;
        top: 38px;

        .container-input {
            border-bottom: 1px solid #EDEDED;
        }
        label {

            padding-left : 15px;
            cursor: pointer;
            width: 100%;
            display: inline-block;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 15px;
        }

    }

    input[type="radio"] {
        visibility: hidden;
        display: none;
    }
}

.filters {

    display: flex;
    justify-content: center;
}

.select2-container {

    font-size: 14px;
    color : #9A9A9A;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {

    color : #9A9A9A;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {

    border-color: #F89900 transparent transparent transparent;

}
