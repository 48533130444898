#search-form {

    text-transform: none;
    min-height: 149px;
    width: 100%;
    display: flex;
    padding: 56px 45px;
    padding-bottom: 7px;
    z-index: 1;
    @apply text-black;
    /* max-width: 375px; */
    background-color: #FAFAFA;

    @screen md {
        background-color: transparent;
        padding-top: 0;
    }
    @screen lg {
        padding-top: 56px;
        background-color: rgba(255, 255, 255, 0.90);
        width: 859px;
        max-width: initial;
        max-height: 217px;
    }

    [class^="day-"], .btn {

        height: 37px;
        min-height: 37px;
    }

    input[type="radio"] {

        visibility: hidden;
        display: none;
    }

    @screen lg {

        #category, #secteur, .day-input, .btn {

            margin-left: 12px;
        }

    }

    .form-section-title {

        font-size: 16px;
        padding-left: 15px;
        cursor: pointer;
    }

    .form-list {

        position: absolute;
        top: 38px;
        background-color: #FAFAFA;
        width: 100%;
        z-index : 15;
        display: none;
    }

    .container-input {

        label {

            cursor: pointer;
            width: 100%;
            display: inline-block;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-left: 15px;

            &:hover {

                background-color: #EFEFEF;
            }

        }

    }


    #category {

        width : 100%;
        margin-left: 0;
        display: inline-block;
        background-color: #fff;
        font-size: 14px;
        padding-top: 7px;
        padding-bottom: 7px;
        position: relative;
        margin-bottom: 10px;

        border: 0;
        border-radius: 0;

        @screen lg {

            width : 223px;
            margin-bottom: 0px;
        }

    }

    .day-input {

        width : 100%;
        margin-bottom: 10px;
        position: relative;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16.281' height='16.281' viewBox='0 0 16.281 16.281'%3E%3Cg transform='translate(0.53 0.75)' opacity='0.5'%3E%3Ccircle cx='5.226' cy='5.226' r='5.226' transform='translate(4.548)' fill='none' stroke='%23000' stroke-miterlimit='10' stroke-width='1.5'/%3E%3Cline x1='6.176' y2='6.176' transform='translate(0 8.825)' fill='none' stroke='%23000' stroke-miterlimit='10' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat ;
        background-position: 10px center;
        text-indent: 15px;

        @screen lg {

            width : 276px;
            margin-bottom: 0px;
        }
    }

    #secteur {

        /* width : 111px; */
        position: relative;
        display: inline-block;
        background-color: #fff;
        position: relative;
        font-size: 14px;
        padding-top: 7px;
        padding-bottom: 7px;

        width: 100%;
        min-width: 99px;
        max-width: calc(100% - 171px);

        border: 0;
        border-radius: 0;

        @screen lg {
            width : 111px;
        }

        #form-secteur-list {

            width : 110px;
            position: absolute;
            background-color: #fff;
            width: 100%;
        }
    }

    .btn {

        width: 152px;
        justify-content: center;
        margin-left: 15px;

        @screen lg {

            margin-left : 12px;
            width: 111px;

        }

    }

    .filters {

        margin-top: 27px;
        margin-bottom: 10px;
        display: none;
        justify-content: start;
        flex-direction: column;

        @screen xl {

            flex-direction : row;
        }

        .input-filter {

            display: flex;
            flex-flow: row wrap;

            @screen lg {
                margin-right: 40px;
            }

            input[type='checkbox'] {

                display: none;

                & + label {

                    background-color: #dddddd;
                    color:#000;
                    opacity: 0.7;
                    margin-right: 5px;
                }

                &:checked + label {

                    background-color: var(--bg-color);
                    color: #FFF;
                    opacity: 1;

                    @screen xl {

                        margin-right: 10px;
                    }

                    &::after {

                        content: '';
                        width: 10px;
                        height: 10px;
                        margin-left: 5px;
                        background-image: url("data:image/svg+xml,%3Csvg id='Calque_2' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.64 14.64'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none;stroke:%23fff;stroke-miterlimit:10;stroke-width:2.5px%7D%3C/style%3E%3C/defs%3E%3Cg id='Calque_1-2'%3E%3Cpath class='cls-1' d='M13.75.88.88 13.75M13.75.88.88 13.75M13.75 13.75.88.88M13.75 13.75.88.88'/%3E%3C/g%3E%3C/svg%3E");
                        display: inline-block;
                    }

                }
            }

            label {

                color : #fff;
                padding: 1px 9px;
                border-radius: 5px;
                font-weight: 600;
                cursor: pointer;
            }
        }

    }

    #free_product {

        position: relative;
        margin-right: 10px;

        &::before {

            content: '';
            display: inline-block;
            width: 18px;
            height: 18px;
            background: #fff;
            border : 2px solid #96E0AF;
            vertical-align: top;
            margin-left: -2px;
            margin-top: -2px;
        }

        &:checked::before {


            background-color: #96E0AF;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.069' height='8.734'%3E%3Cpath d='M.707 3.28l4.042 4.041L11.362.708' fill='none' stroke='%23fff' stroke-width='2'/%3E%3C/svg%3E");
            background-repeat : no-repeat;
            background-position: center center;

        }

    }

    #event-filter {

        text-align: center;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.5);
        margin-top: 25px;
        margin-bottom: 25px;
        @apply lg:mb-0;
    }
}
