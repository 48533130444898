#error {

    display: flex;
    flex-direction: column;
    height: 100vh;
    align-items: center;
    justify-content: center;
    background-image: url("/images/404.jpg");
    background-size: cover;

    &::after {

        content: '';
        position: absolute;
        background-color: rgba(0, 0, 0, 0.45);
        display: block;
        width: 100%;
        height: 100%;
    }

    #content {

        z-index: 2;
        text-align: center;
        color : #fff;

        #number-error {

            font-size: 149px;
            position: relative;
            font-family: 'Thunder';

            &::before {

                content: '';
                width: 105px;
                height: 106px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='104.138' height='105.122' viewBox='0 0 104.138 105.122'%3E%3Cpath id='Tracé_492' data-name='Tracé 492' d='M35.965,83.133l66.367-67.169L88.357,1.988,21.987,69.157V0H.038L0,105.122l104.138-.038V83.133Z' transform='translate(104.138 105.122) rotate(180)' fill='%23f89900'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                margin-left: 10px;
                display: inline-block;
                left: 200px;
            }

        }

        h1 {

            font-size: 37px;
            margin-bottom: 30px;
        }

        .btn {

            height: 41px;
            width: 208px;
            justify-content: center;

            &::after {

                content: '';
                width: 18px;
                height: 18px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17.201' height='17.201'%3E%3Cpath fill='%23fff' d='m12.442 7.42-11.293.065v2.296l11.293-.066-5.598 5.598 1.802 1.803 8.556-8.556L8.646.004 6.843 1.807Z'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                margin-left: 10px;
                display: inline-block;
            }
        }

    }

}
