.profil {

    h1 {

        font-size:25px;
        font-weight: 500;
        color: #000;
    }

    .user-image {

        /* background-image: url("data:image/svg+xml,%3Csvg data-name='Groupe 877' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 95 95'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 283' fill='none' d='M0 0h95v95H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Groupe 876' clip-path='url(%23a)' fill='none' stroke='%23040405' stroke-width='2'%3E%3Cpath data-name='Tracé 507' d='M60.507 40.086a12.589 12.589 0 1 1-12.593-12.593 12.589 12.589 0 0 1 12.593 12.593Z' stroke-miterlimit='10'/%3E%3Cpath data-name='Tracé 508' d='m74.445 84.905-.065-6.523A18.485 18.485 0 0 0 55.9 59.898H39.934a18.485 18.485 0 0 0-18.486 18.484l.065 6.523' stroke-miterlimit='10'/%3E%3Ccircle data-name='Ellipse 18' cx='46.5' cy='46.5' r='46.5' transform='translate(1 1)'/%3E%3C/g%3E%3C/svg%3E"); */
        width: 95px;
        flex-basis: 95px;
        height: 95px;
        background-repeat: no-repeat;
        margin-right: 25px;

        img {

            border-radius: 50%;
            width: 95px;
            height: 95px;
            object-fit: cover;
        }
    }

    .user-description {
        flex-grow: 1;
        width: 70%;
    }

    #card {

        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 20px #0000001A;
        border-radius: 3px;
        margin-bottom: 60px;
        color: #9A9A9A;
        align-items: center;
        padding: 10px;
        font-size: 14px;

        @screen md {
            padding: 37px;
            font-size: 16px;
        }
    }

}
