#search-filter {

    font-weight: bold;
    margin-bottom: 33px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @screen lg {

        flex-direction: row;
    }

    input[type="checkbox"] + label {

        margin-right: 40px;

    }

    input[type="checkbox"] + label:last-child {

        margin-right: 0px;

        @screen lg {

            margin-right : 40px;
        }

    }

    #order-list,
    #secteur-list,
    .save {

        height: 37px;
    }

    #order-list,
    #secteur-list {


        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 15px;
        padding-right: 0px;
        width: 156px;
        font-weight: normal;
        margin-top: 20px;
        margin-bottom: 20px;

        background-color: #F4F4F4;
        border:0;
        border-radius: 0;

        @screen lg {
            margin-right: 25px;
        }
        @screen xl {

            margin-top : 0;
            margin-bottom : 0;

        }

        .form-section-title {

            font-size: 16px;
        }

    }

    #payant {

        &::before {

            @apply border-peel;

        }

        &:checked::before {

            @apply bg-peel;

        }

    }

}

.save {

    &:disabled {

        background-color: #eee;
    }
}

#alert-area {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 75px;

    /* .btn {

        width: fit-content;
        justify-content: center;
    } */
}

#pagination {

    margin-top: 60px;
    margin-bottom: 60px;

    [aria-current="page"] span {
        width: 33px;
        height: 33px;
        justify-content: center;
    }

}
