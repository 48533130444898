#roadmap {

    border-top: 2px solid #BCBCBC;
    margin-bottom: 30px;
    padding: 0px 45px;

    &.step-1 {
        border-image: linear-gradient(to right, #F89900 0%, #F89900 11%,#BCBCBC 10%);
        border-image-slice: 1;
    }

    &.step-2 {
        border-image: linear-gradient(to right, #F89900 0%, #F89900 30%,#BCBCBC 30%);
        border-image-slice: 1;
    }

    &.step-3 {
        border-image: linear-gradient(to right, #F89900 0%, #F89900 50%,#BCBCBC 50%);
        border-image-slice: 1;

    }
    &.step-4 {
        border-image: linear-gradient(to right, #F89900 0%, #F89900 70%,#BCBCBC 70%);
        border-image-slice: 1;
    }

    &.step-5 {
        border-color: #F89900;
    }

    .step {

        text-align: center;

        &::before {

            content: '';
            border-radius: 50%;
            background-color: #fff;
            width: 26px;
            height: 26px;
            display: block;
            border: 2px solid #BCBCBC;
            margin: auto;
            transform: translateY(-50%);
        }

        &.active{

            font-weight: bold;

            &::before {

                @apply border-peel;

            }

        }
    }
}
