#box-annonce {

    width: 870px;
    max-width: 100%;
    margin-bottom: 50px;

    .dimensions {

        label {

            font-weight: 400;
            font-size: 14px;
        }
    }

    .dispo {

        font-size: 14px;

        .flex {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            row-gap: 5px;

            @screen md {
                flex-direction: row;
                align-items: center;
            }
        }
    }

    label,
    .label {

        font-weight: 500;
    }

    #labeldon {

        &::after {

            content: '';
            display: inline-block;
            width: 26px;
            height: 19px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.677' height='18.319'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 258' transform='translate(-3)' fill='%2396e0af' stroke='rgba(0,0,0,0)' d='M0 0h25.677v18.319H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Groupe 798' transform='translate(3)' clip-path='url(%23a)' fill='%2396e0af' stroke='rgba(0,0,0,0)'%3E%3Cpath data-name='Tracé 473' d='M11.935 8.749c.274.215.524.413.684.545a.044.044 0 0 0 .058 0c.159-.133.412-.332.688-.549 1.323-1.042 3.322-2.615 3.653-4.165a2.408 2.408 0 0 0 .053-.5 2.484 2.484 0 0 0-.521-1.506 2.316 2.316 0 0 0-3.02-.593l-.052.033a2.35 2.35 0 0 0-.54.461l-.289.334-.288-.335a2.32 2.32 0 0 0-3.611.1 2.482 2.482 0 0 0-.521 1.511 2.392 2.392 0 0 0 .053.5c.331 1.549 2.329 3.123 3.652 4.164'/%3E%3Cpath data-name='Tracé 474' d='M16.084 12.399a1.894 1.894 0 0 1 .078.215l3.41-3.674c-.018-.016-.034-.033-.052-.048a1.172 1.172 0 0 0-.839-.249 1.38 1.38 0 0 0-.849.4l-2.647 2.377a2.208 2.208 0 0 1 .9.984'/%3E%3Cpath data-name='Tracé 475' d='M22.23 9.035a1.3 1.3 0 0 0-1.81.11l-4.364 4.7a1.986 1.986 0 0 1-2.152 1.109l-4.545-1.087.177-.742 4.54 1.084a1.243 1.243 0 0 0 1.328-.749.909.909 0 0 0-.017-.752 1.53 1.53 0 0 0-.8-.746l-.016-.006-.214-.081a99.401 99.401 0 0 0-1.574-.584 77.678 77.678 0 0 0-2.33-.814c-4.491-1.483-4.38-1.222-8.142-.122-.2.06-.291.119-.828.273v4.958l4.51.353.09.04c.685.3 3 1.335 3.992 1.8a5.029 5.029 0 0 0 1.555.528 2.879 2.879 0 0 0 1.6-.337c1.812-.86 4.939-2.487 5.29-2.67l.015-.008 3.831-4.43a1.3 1.3 0 0 0-.115-1.813'/%3E%3C/g%3E%3C/svg%3E");
            vertical-align: -2px;

        }

        #don {

            width: initial;
            display: inline-block;
        }

    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'],
    input[type='date'],
    textarea {

        padding: 10px;
        padding-left: 15px;
        border: 1px solid rgba(188, 188, 188, 0.3);
        @apply rounded-md;

    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {

        height: 37px;
    }

    input[type='radio'],
    input[type='checkbox'] {

        &::before {

            @apply border-peel

        }

        &:checked::before {

            @apply bg-peel;
        }
    }

    #tag-list {

        display: inline-block;
        padding: 5px;

        li {

            display: inline-block;
            margin-right: 10px;
            padding: 0 5px;
            font-weight: 500;
            font-size: 14px;
            height: 22px;
            @apply bg-peel text-white rounded-md;

            &::after {

                content: '';
                display: inline-block;
                width : 10px;
                height : 10px;
                vertical-align: middle;
                margin-left: 12px;
                margin-right: 7px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.731' height='9.731'%3E%3Cg data-name='Groupe 1050' fill='none' stroke='%23fff'%3E%3Cpath data-name='Tracé 561' d='m.354.354 9.023 9.024'/%3E%3Cpath data-name='Tracé 562' d='M9.378.355.354 9.379'/%3E%3C/g%3E%3C/svg%3E");

            }
        }
    }

    .tagify {

        --tag-bg: #F89900;
        --tag-text-color: #fff;
        --tag-remove-btn-color: #fff;

        min-width: 100%;
        min-height: 100%;
        border : none;
        @apply appearance-none focus:outline-none;

        .tagify__tag__removeBtn::after {

            content: '';
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9.731' height='9.731'%3E%3Cg data-name='Groupe 1050' fill='none' stroke='%23fff'%3E%3Cpath data-name='Tracé 561' d='m.354.353 9.023 9.024'/%3E%3Cpath data-name='Tracé 562' d='M9.378.354.354 9.378'/%3E%3C/g%3E%3C/svg%3E");
            width: 10px;
            height: 10px;
            background-repeat: no-repeat;
            vertical-align: middle;
        }

        .tagify__tag>div>* {

            vertical-align: unset;
        }
    }

    #tag-area {

        border: 1px solid rgba(188, 188, 188, 0.3);
        min-height : 69px;
        width: 100%;

    }

    .dropdown {


        width: 100%;
        margin: 0;
        margin-top: 16px;

        @screen md {
            width: 223px;
        }

        .form-section-title {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    #unite {
        @apply mt-[5px] ml-[5px];
    }

    #astuces {

        color: #9A9A9A;
        padding: 30px;
        /* height: 229px; */
        font-weight : 600;
        line-height: 23px;
        @apply shadow-md;

        h2 {

            font-size : 20px;
            line-height: initial;

            &::before {
                content: '';
                width : 22px;
                height : 19px;
                display: inline-block;
                vertical-align: middle;
                background-repeat: no-repeat;
                margin-right: 7px;
                background-image: url("data:image/svg+xml,%3Csvg data-name='Groupe 1014' xmlns='http://www.w3.org/2000/svg' width='21.468' height='18.12'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 307' fill='%239a9a9a' d='M0 0h21.468v18.12H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Groupe 1013' clip-path='url(%23a)'%3E%3Cpath data-name='Tracé 544' d='m5.8 12.889-1.1-.344a7.265 7.265 0 0 1-.573.72 2.288 2.288 0 0 1-2.571.533 2.239 2.239 0 0 1-1.523-2c-.051-.71-.033-1.427-.027-2.141 0-1.118-.031-2.24.04-3.354A2.2 2.2 0 0 1 1.617 4.29a2.314 2.314 0 0 1 2.612.644 1.433 1.433 0 0 1 .277.369c.1.218.209.265.453.188q5.731-1.8 11.469-3.583a.463.463 0 0 0 .336-.332 2.419 2.419 0 0 1 4.677.577c.014.145.026.292.026.438v12.936a2.436 2.436 0 0 1-4.72.981.405.405 0 0 0-.3-.3c-.495-.143-.985-.3-1.487-.459Zm10.74 1.731V3.498c-.024.006-.08.019-.135.036Q10.746 5.3 5.086 7.06c-.177.055-.217.139-.216.311.007 1.11.009 2.22 0 3.329a.305.305 0 0 0 .259.358q4.69 1.454 9.375 2.926l2.038.637m3.2 1.608a1.023 1.023 0 0 0 .175-.711V2.585a2.118 2.118 0 0 0-.013-.283.876.876 0 0 0-1.4-.585.96.96 0 0 0-.362.839v13.01a1.433 1.433 0 0 0 .018.283.849.849 0 0 0 .6.682.917.917 0 0 0 .979-.3M3.058 12.162a.928.928 0 0 0 .266-.727V7.593c0-.361.015-.723-.009-1.083a.876.876 0 0 0-1.37-.677.911.911 0 0 0-.39.823v4.8a1.235 1.235 0 0 0 .015.231.843.843 0 0 0 .61.7.837.837 0 0 0 .877-.219' fill='%239a9a9a'/%3E%3C/g%3E%3C/svg%3E");
            }
        }
    }

    #circulaire {

        /* height: 260px; */
        margin-bottom: 41px;
        background-image: url('/images/circulaire-annonce.jpg');
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 30px;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        @apply rounded-md;
    }

    .disclaimer {

        color : #9A9A9A;
        font-size: 12px;
    }

    .filepond--root {

        width: 145px;
        height: 147px;
        border: 1px solid #BCBCBC;

        @apply mx-auto;
        @apply my-4;

        .filepond--drop-label {

            display: flex;
            height: 100%;

            label {

                display: flex;
                flex-direction: column ;
                align-items: center;
                font-size: 14px;
                color: #BCBCBC;

                p {

                    margin-top: 10px;

                }

            }
        }

        .filepond--panel-root {

            background: #fff;


        }
    }
    #prix_reduit {
        max-width: 75px;

        @screen md {
            max-width: unset;
        }
    }
}

.upload-photo {

    #box-annonce {

        .disclaimer {

            font-size: 14px;

        }

    }
}

.edit-annonce {

    .picture {

        img {

            height: 200px;
            width: 100%;
            object-fit: cover;
        }
    }

}
