.break::after{
    content: "\a";
    white-space: pre;
}

.plus {

    position: relative;

    &::after {

        position: absolute;
        content : '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-left: 10px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.002' height='18.002' viewBox='0 0 18.002 18.002'%3E%3Cg id='Groupe_886' data-name='Groupe 886' transform='translate(4139.394 1367.602)'%3E%3Cpath id='Tracé_512' data-name='Tracé 512' d='M-4133.258-1367.6v18' transform='translate(2.865 0)' fill='none' stroke='%23fff' stroke-width='3'/%3E%3Cpath id='Tracé_513' data-name='Tracé 513' d='M0,0V18' transform='translate(-4121.392 -1358.6) rotate(90)' fill='none' stroke='%23fff' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
    }

}

.oeil {

    position: relative;

    &::after {

        position: absolute;
        content : '';
        display: inline-block;
        width: 24px;
        height: 18px;
        margin-left: 10px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.978' height='18.152' viewBox='0 0 23.978 18.152'%3E%3Cg id='Groupe_887' data-name='Groupe 887' transform='translate(3484.464 1516.369)'%3E%3Cpath id='Tracé_514' data-name='Tracé 514' d='M26.222,12.576C24.953,9.605,21.236,5,15.865,5S6.777,9.605,5.507,12.576c1.27,2.971,4.986,7.576,10.358,7.576S24.953,15.547,26.222,12.576Z' transform='translate(-3488.339 -1519.869)' fill='none' stroke='%23fff' stroke-miterlimit='10' stroke-width='3'/%3E%3Cpath id='Tracé_515' data-name='Tracé 515' d='M44.936,24.128a3.483,3.483,0,1,0-3.483,3.483,3.483,3.483,0,0,0,3.483-3.483' transform='translate(-3513.927 -1531.421)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
    }
}

#bam-cest-quoi {

    writing-mode: vertical-rl;
    text-orientation: initial;
    transform: scale(-1);
    position: fixed;
    top: 184px;
    right: 0;
    display: flex;
    flex-flow: column nowrap;
    background-color: #96E0AF;
    padding: 13px 7px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    transition: all .2s ease-in-out;
    width: 35px;
    color : #000;
    z-index: 200;

    @apply hidden lg:flex;

    .part-initial{
        text-transform: none;
        transform: translateX(0);
        transition: transform .25s ease-in-out, opacity .1s ease-in-out;
        transition-delay: transform .2s;
        font-size: 16x;
        font-weight: 500;
        line-height: 19px;
        text-align: center;
        @apply opacity-100 h-full flex min-h-full items-center;

        p {
            flex-basis: 100%;
        }

    }
    /* .part-hover {
        text-transform: none;
        flex-basis: 100%;
        transform: translateX(50%);
        transition: transform .25s ease-in-out, opacity .4s ease-in-out;
        transition-delay: transform .1s;
        font-size: 16x;
        font-weight: 500;
        line-height: 19px;
        text-align: center;

        margin-right: 0;
        @apply flex items-center opacity-0 min-h-full;

        p {
            flex-basis: 100%;

            .picto-play {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 23 23'%3E%3Cg transform='rotate(-90 156.87 479.392)'%3E%3Ccircle cx='11.5' cy='11.5' r='11.5' transform='translate(613.262 322.523)'/%3E%3Cpath d='m631.262 334.023-10 6.5v-13Z' fill='%2396e0af'/%3E%3C/g%3E%3C/svg%3E");
                width: 20px;
                height: 20px;
                display: inline-block;
                transform: rotate(180deg);
                margin-bottom: 6px;
            }
        }
    } */

    /* &:hover  {
        cursor: pointer;
        transform: scale(-1);
        width: 40px;

        .part-initial {
            transform: translateX(75%);
            @apply opacity-0;
        }

        .part-hover {
            transform: translateX(80%);
            @apply opacity-100 h-full flex items-center;
        }

    } */
}

.shadow-xl {
    box-shadow: 0px 0px 20px #0000001A !important;
}
