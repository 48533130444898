#breadcrumbs {

    margin-bottom : 39px;
    margin-top : 0px;

    li {

        display : inline-block;

        &::after {

            content: '';
            display : inline-block;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18.52 18.52'%3E%3Cg data-name='Groupe 748'%3E%3Cpath data-name='Tracé 366' d='m14.2 9.26-6.745 6.746 1.803 1.802 8.556-8.556L9.258.696 7.455 2.5Z' stroke='rgba(0,0,0,0)'/%3E%3C/g%3E%3C/svg%3E");
            width: 18px;
            height: 18px;
            vertical-align : middle;
            margin-right: 9px;
        }

        &:last-child::after {

            background-image: none;
        }

        a {

            font-weight: bold;

        }
    }
}
