#cms {

    padding-top: 143px;
    color : #9A9A9A;

    hr {

        margin-top: 120px;
        background-color: rgba(0,0,0,0.10);
        border: none;
        height: 0.5px;
    }

    h1, h2 {

        font-weight: 500;

    }

    strong {

        color: #000;
    }

    h1 {

        color : #000;
        font-size: 25px;
        margin-bottom: 21px;
    }

    h2 {

        font-size: 20px;
        @apply text-peel
    }

    p {

        margin-top: 32px;
        margin-bottom: 32px;
    }

    a {

        font-weight: bold;
        text-decoration: underline;
    }


}
