#nav-header {

    position: fixed;
    width: 100%;
    color: #fff;
    padding: 12px 0;
    display: flex;
    align-items: center;
    z-index : 900;
    background-color: #000;

    @screen lg {

        background-color : var(--bg-color);

    }

    #logo {

        width: 231px;
        height: 22px;

    }

    #hamb-menu {

        position: absolute;
        top: 5px;

    }

    .cta {

        &::before {

            content: '';
            display: inline-block;
            width: 23px;
            height: 23px;
            margin-right: 6px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.369' height='23.369'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='none' d='M0 0h23.369v23.369H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M23.369 11.685A11.685 11.685 0 1 1 11.684 0a11.685 11.685 0 0 1 11.685 11.685' fill='%23f89900'/%3E%3Cpath d='M18.673 9.737h-5.041v-5.04h-3.9v5.04H4.697v3.9h5.04v5.04h3.9v-5.04h5.04Z'/%3E%3C/g%3E%3C/svg%3E");

        }

        &:hover {

            background-color: #000;
        }

        &:hover::before {

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25.193' height='25.193'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 199' d='M0 0h25.193v25.193H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg data-name='Groupe 633'%3E%3Cg data-name='Groupe 632' clip-path='url(%23a)'%3E%3Cpath data-name='Tracé 400' d='M25.193 12.6A12.6 12.6 0 1 1 12.6 0a12.6 12.6 0 0 1 12.6 12.6'/%3E%3Cpath data-name='Tracé 401' d='M20.13 10.497h-5.434V5.063h-4.2v5.434H5.063v4.2h5.434v5.434h4.2v-5.435h5.434Z' fill='%23f89900'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
        }
    }

    #primary-menu {

        text-transform: uppercase;
        position: absolute;
        left: 0;
        top: 46px;
        width: 100%;
        background-color: #000;
        height: calc(100vh - 46px);
        justify-content: center;
        align-items: center;
        flex-direction: column;

        @screen lg {

            display: flex;
            position : initial;
            top : 0;
            width : auto;
            background-color : initial;
            height : auto;
            display: initial;
            justify-content: initial;
            align-items: initial;
            flex-direction: initial;

        }

        a {

            display: block;
            align-items: center;
            flex-direction: column;
            font-size: 18px;
            margin-top: 20px;
            margin-bottom: 20px;
            @apply lg:px-4 pt-1 ;

            &.flex {

                display: flex;
                flex-direction: initial;

            }

            @screen lg {

                display: inline-flex;
                margin-top: 0px;
                margin-bottom: 0px;
                font-size: 12px;

            }

            svg {

                /* display: inline-block; */
                display: inline-flex;
                display: -webkit-inline-flex;
                margin-right: 10px;
                width: 24px;
                height: 24px;

                @screen lg {

                    display: block;
                    margin-right: 0px;
                    margin-bottom: 5px;

                }
            }

            &.user:hover,
            &.ringbell:hover,
            &.heart:hover,
            &.loupe:hover,
            &.user.active,
            &.ringbell.active,
            &.heart.active,
            &.loupe.active {

                svg  path {

                    fill: #fff;

                }

            }

            &.help:hover,
            &.help.active  {

                svg {

                    path {

                        fill: #000;

                    }

                    circle {

                        fill: #fff;

                    }

                }
            }
        }
    }
}
