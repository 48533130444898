.register {

    #box-card {

        .type-check {



            label {

                display: block;
                color: #fff;
                font-weight: 500;
                text-align: center;
            }

            input[type='radio'] {

                position: absolute;
                left:0;
                z-index: -1;

                & + span {

                    cursor: pointer;
                    display: block;
                    width: 100%;
                    height: 100%;
                    padding: 10px 0;
                    @apply bg-peel hover:bg-peelhover transition ease-in-out delay-150 rounded-md;

                }

                &:checked + span {
                    background-color:#000;
                    color:#fff;
                }

            }

        }

        input[type='file'] {

            display: none;
        }

    }

}

#ville.dropdown {

    background-color: #fff;
    color: #000;
    border: 1px solid #BCBCBC;
    @apply rounded-md;

    .form-list {

        background-color: #FAFAFA;
        top: 37px;
        @apply rounded-b-md;

        label {

            margin: 0;
            padding-top: 2px;
            padding-bottom: 2px;
        }

    }
}

#label-avatar {

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='84'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath data-name='Rectangle 283' transform='translate(0 .33)' fill='none' d='M0 0h75v75H0z'/%3E%3C/clipPath%3E%3Cfilter id='b' x='43' y='27' width='57' height='57' filterUnits='userSpaceOnUse'%3E%3CfeOffset/%3E%3CfeGaussianBlur stdDeviation='3' result='blur'/%3E%3CfeFlood flood-opacity='.2'/%3E%3CfeComposite operator='in' in2='blur'/%3E%3CfeComposite in='SourceGraphic'/%3E%3C/filter%3E%3C/defs%3E%3Cg data-name='Groupe 1066'%3E%3Cg data-name='Groupe 879'%3E%3Cg data-name='Groupe 877'%3E%3Cg data-name='Groupe 876' transform='translate(0 -.33)' clip-path='url(%23a)' fill='none' stroke='%23040405' stroke-width='2'%3E%3Cpath data-name='Tracé 507' d='M47.769 31.977a9.939 9.939 0 1 1-9.942-9.942 9.939 9.939 0 0 1 9.942 9.942Z' stroke-miterlimit='10'/%3E%3Cpath data-name='Tracé 508' d='m58.773 67.36-.052-5.15a14.593 14.593 0 0 0-14.592-14.592h-12.6A14.593 14.593 0 0 0 16.933 62.21l.052 5.15' stroke-miterlimit='10'/%3E%3Ccircle data-name='Ellipse 18' cx='36.5' cy='36.5' r='36.5' transform='translate(1 1.33)'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cg data-name='Groupe 881'%3E%3Cg transform='translate(.001 -.003)' filter='url(%23b)'%3E%3Ccircle data-name='Ellipse 20' cx='19.5' cy='19.5' r='19.5' transform='translate(52 36)' fill='%23fff'/%3E%3C/g%3E%3Cg data-name='Groupe 880' fill='none' stroke='%23000' stroke-miterlimit='10' stroke-width='1.5'%3E%3Cpath data-name='Tracé 511' d='m65.518 63.747-4.1 1.805a.313.313 0 0 1-.412-.412l1.805-4.1a2.587 2.587 0 0 1 .539-.787l12.358-12.366 3.046-3.047a.433.433 0 0 1 .612 0l2.348 2.348a.433.433 0 0 1 0 .612L66.305 63.213a2.588 2.588 0 0 1-.787.534Z'/%3E%3Cpath data-name='Ligne 6' d='m75.707 47.886 2.959 2.959'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat ;
    width: 100px;
    height: 78px;
    display: inline-block;
    vertical-align: middle;

    input[type='file'] {

        display: none;
    }
}
