#colophon {

    @apply border-peel;
    border-top: 8px solid #F89900;
    font-size: 14px;
    background-color: #FAFAFA;

    nav {

        color : #9A9A9A;

        .day-footer-title {

            color: #000;
            font-weight: bold;
            margin-bottom: 8px;

        }

        .cta {

            color : #fff;
            height: 25px;
            padding: 0px 10px;
            border-radius: 8px;
            margin-top: 17px;
        }

        .rs-link {

            &::before {

                content : '';
                display: inline-block;
                vertical-align: bottom;
                width: 26px;
                height: 25px;
                margin-right: 5px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='25.428' height='25.428' viewBox='0 0 25.428 25.428'%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Crect width='25.428' height='25.428'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg clip-path='url(%23a)'%3E%3Cpath d='M25.428,12.714A12.714,12.714,0,1,1,12.714,0,12.714,12.714,0,0,1,25.428,12.714'/%3E%3Cpath d='M912.551,794.187v-.162q0-2.378,0-4.755a2.393,2.393,0,0,0-.244-1.046,1.605,1.605,0,0,0-.29-.422,1.308,1.308,0,0,0-.8-.4,1.536,1.536,0,0,0-.525.019,1.34,1.34,0,0,0-.75.455,2.205,2.205,0,0,0-.415.77,1.471,1.471,0,0,0-.074.465q0,2.439,0,4.877a.415.415,0,0,1-.014.164c-.027,0-.053.009-.08.01-.578.016-2.551.007-2.688-.013,0-.023-.008-.049-.009-.075s0-.072,0-.108q0-4.227,0-8.454c0-.058,0-.116,0-.169a.2.2,0,0,1,.139-.023h2.617a.119.119,0,0,1,.032.11c0,.235,0,.47,0,.7v.153a.285.285,0,0,0,.052-.038,3.024,3.024,0,0,1,1.7-1.111,4.277,4.277,0,0,1,1.409-.137,2.487,2.487,0,0,1,1.58.66,2.977,2.977,0,0,1,.635.871,4.685,4.685,0,0,1,.4,1.246,6.752,6.752,0,0,1,.106,1.226q0,2.52,0,5.04a.437.437,0,0,1-.005.132.162.162,0,0,1-.02.022.026.026,0,0,1-.013,0c-.041,0-.081,0-.122,0l-2.587,0a.215.215,0,0,1-.045-.017' transform='translate(-895.506 -775.329)' fill='%23f89900'/%3E%3Cpath d='M531.079,820.523a.168.168,0,0,1-.012-.042q0-4.369,0-8.737a.107.107,0,0,1,.009-.032.094.094,0,0,1,.014-.014.029.029,0,0,1,.013,0c.031,0,.063,0,.094,0l2.614,0a.144.144,0,0,1,.034.011c0,.021.009.047.01.072s0,.072,0,.108v8.634c-.033.006-.058.012-.084.014s-.063,0-.095,0h-2.438a.49.49,0,0,1-.159-.011' transform='translate(-524.528 -801.699)' fill='%23f89900'/%3E%3Cpath d='M513.488,447.881a1.632,1.632,0,1,1-1.638,1.631,1.625,1.625,0,0,1,1.638-1.631' transform='translate(-505.549 -442.367)' fill='%23f89900'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
            }
        }
    }

    #reassurance {

        /* font-weight: bold; */
        @apply font-medium;
        font-size: 23px;

        @screen lg {
            font-size: 14px;
        }

        #logos-reassurance {

            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            margin-top: 12px;

            a {
                @apply basis-4/12;
                @apply text-center;
                @apply mb-7;

                @screen lg {
                    @apply basis-auto;
                }
            }

            img {

                height: 61px;
                display: inline-block;
                margin-right: 6px;
            }

        }
    }

    #logo-footer {

        width: 100%;
        place-items: start;
        margin-top: 52px;

        @screen lg {
            place-items: end;
            margin-top: 0;
        }

    }
}
