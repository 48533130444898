
.modal-header {
	align-items: baseline;
	display: flex;
	justify-content: space-between;
}

.close {
	background: none;
	border: none;
	cursor: pointer;
	display: flex;
	height: 16px;
	text-decoration: none;
	width: 16px;

	svg {
		width: 16px;
	}
}

.modal-wrapper {
	align-items: center;
	background: rgba(0, 0, 0, .5) 0% 0% no-repeat padding-box;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

#modal, #favoris-modal, #save-modal {
	opacity: 0;
	transition: opacity .3s ease-in-out;
	visibility: hidden;

	&.open {
		opacity: 1;
		visibility: visible;

		.modal-body {
			opacity: 1;
			transform: translateY(1);
		}
	}

	.modal-body {
		max-width: 600px;
        background-color: #FFF;
		opacity: 0;
		transform: translateY(-100px);
		transition: opacity .3s ease-in-out;
		width: 100%;
		z-index: 1;
        box-shadow: 0px 0px 20px #0000001A;
        border-radius: 5px;
        color: #9A9A9A;
        font-size: 12px;
        padding: 35px 25px 30px;

        @screen lg {
            padding: 35px 50px 30px;
        }
	}

    input[type='checkbox'] {
        margin-bottom: 15px;

        &:checked::before {
            background-color:#F89900;
        }

        &::before {
            border: 2px solid #F89900;
        }

    }

    textarea {
        border: 1.5px solid #9A9A9A;
        border-radius: 4px;
        padding: 7px 11px;

        resize: none;
        font-size: 12px;
        line-height: 17px;
    }


    /* Custom button Radio */

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label {
        margin-bottom: 15px;
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }

    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 18px;
        height: 18px;
        border: 2px solid #F89900;
        border-radius: 3px;
        background: #fff;
    }

    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: #F89900;
        position: absolute;
        top: 4px;
        left: 4px;
        border-radius: 0%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }

    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

.outside-trigger {
	bottom: 0;
	cursor: default;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

.button__link {
	text-decoration: none;
}

.title {

    font-size: 20px;
    color : #000;
    margin-bottom: 15px;
    font-weight: bold;
}

.text-modal {

    font-size: 16px;
    color: #000;
    margin-bottom: 15px;
}

.btn {

    font-size: 16px;
}

#myModal {
    display: none;
    z-index: 9999;
    position: fixed;
    background-color: rgba(0,0,0,.4);
    /* width: 900px;
    max-width: 80%; */
    /* height: auto; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    max-height: 100%;
    max-width: 100%;

    #closeMyModalBtn {
        z-index: 99999;
    }
    iframe {
        /* position: absolute; */
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-height: 100%;
        max-width: 100%;
    }
    #closeMyModalBtn {
        background-color: #000;
        color: #FFF;
        border-radius: 3px;
        font-size: 12px;
        padding: 2px 5px;
        margin-top: -30px;

        &:hover {
            color: #FFF;
            background-color: #F89900;
        }
    }
    .modalContainer {
        height:622px;
        width:1105px;
        margin:auto;
        max-height:100%;
        max-width:100%;
    }
}
