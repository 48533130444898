.link-card {

    &:visited {

        img {
            opacity: 0.7;
        }
    }

}
.day-card {

    position: relative;
    background: #fff;
    transition: transform .2s;
    border-radius: 3px;

    &:hover {

        transform: scale(1.05);
    }

    .thumbnail {

        height: 230px;
        overflow: hidden;

        img{
            width: 100%;
            object-fit: cover;
            height: 100%;


        }


    }

    .meta-annonce {

        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        width : 100%;
        padding: 12px 14px;
        flex-direction: column;

        @screen xl {

            flex-direction : row;
        }

        .category, .ville, .don-tag {

            @apply text-white;
            padding: 0px 5px;
            border-radius: 3px;
            font-weight: 500;
            width: fit-content;

            @screen xl {

                width : auto;
            }

        }

        .ville {

            margin-top: 10px;

            @screen xl {

                margin-top:0;

            }
        }

        .category {

            @apply bg-peel;
        }

        .don-tag {

            background-color : #96E0AF;
            margin-top : 10px;
            color : #000;
            width : fit-content;

            &::before {

                content: '';
                display: inline-block;
                width: 21px;
                height: 17px;
                margin-right: 10px;
                vertical-align: -3px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='21.822' height='15.569' viewBox='0 0 21.822 15.569'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_258' data-name='Rectangle 258' width='21.822' height='15.569' transform='translate(-3)'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Groupe_798' data-name='Groupe 798' transform='translate(3)' clip-path='url(%23clip-path)'%3E%3Cpath id='Tracé_473' data-name='Tracé 473' d='M31.237,6.024c.232.183.446.351.581.464a.037.037,0,0,0,.049,0c.136-.113.35-.282.585-.467,1.124-.885,2.823-2.223,3.1-3.54a2.047,2.047,0,0,0,.045-.424A2.111,2.111,0,0,0,35.159.776a1.968,1.968,0,0,0-2.566-.5L32.548.3a2,2,0,0,0-.459.392l-.245.284L31.6.691a1.972,1.972,0,0,0-3.069.086,2.109,2.109,0,0,0-.443,1.285,2.033,2.033,0,0,0,.045.424c.281,1.316,1.98,2.654,3.1,3.539' transform='translate(-21.543 1.412)'/%3E%3Cpath id='Tracé_474' data-name='Tracé 474' d='M50.513,31.528a1.61,1.61,0,0,1,.067.182l2.9-3.122c-.015-.013-.029-.028-.044-.041a1,1,0,0,0-.713-.212,1.172,1.172,0,0,0-.721.336l-2.25,2.02a1.876,1.876,0,0,1,.764.836' transform='translate(-37.294 -20.99)'/%3E%3Cpath id='Tracé_475' data-name='Tracé 475' d='M18.711,28.621a1.1,1.1,0,0,0-1.538.094l-3.709,4a1.688,1.688,0,0,1-1.834.94L7.772,32.73l.15-.63,3.859.921a1.057,1.057,0,0,0,1.128-.64.773.773,0,0,0-.014-.639,1.3,1.3,0,0,0-.68-.634L12.2,31.1h0l-.182-.069c-.582-.22-.746-.281-1.338-.5-.748-.273-1.4-.5-1.98-.692-3.817-1.261-3.723-1.038-6.92-.1-.174.051-.248.1-.7.232v4.214l3.833.3.077.034c.583.259,2.549,1.134,3.393,1.528A4.274,4.274,0,0,0,9.7,36.5a2.447,2.447,0,0,0,1.357-.286c1.54-.731,4.2-2.114,4.5-2.269l.013-.006,3.256-3.765a1.1,1.1,0,0,0-.1-1.541' transform='translate(-0.269 -20.942)'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;
            }

        }

        .ville {

            background-color: #000;
            height : fit-content;

            &::before {

                content: '';
                display: inline-block;
                width: 13px;
                height: 13px;
                margin-right: 10px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.21' height='12.117' viewBox='0 0 12.21 12.117'%3E%3Cg transform='translate(12.21) rotate(90)'%3E%3Cpath d='M483.793,239.26l-7.939,8.032,1.623,1.623,7.939-8.032V248.8h2.549l0-12.1-12.1,0v2.55Z' transform='translate(-475.854 -236.706)' fill='%23f89900'/%3E%3C/g%3E%3C/svg%3E");
                background-repeat: no-repeat;

            }
        }

    }

    h3 {

        font-size: 20px;
        font-weight: 500;
        max-width: 296px;
        line-height: 23px;
    }

    .etat {

        @apply text-peel;
        font-size: 14px;
        font-weight: bold;
    }

    .stock {

        color : #9A9A9A;
        margin-bottom: 13px;
    }

    .whislist {

        font-size : 12px;
        color : #9A9A9A;

        &:hover, &.active {
            cursor: pointer;

            &::before {

                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.893' height='18.007' viewBox='0 0 19.893 18.007'%3E%3Cg transform='translate(0.75 0.75)'%3E%3Cpath d='M-617.373,730.175a5.278,5.278,0,0,0-.066-7.395,5.281,5.281,0,0,0-7.469,0c-.077.077-.145.159-.217.24-.051-.055-.1-.113-.15-.166a5.281,5.281,0,0,0-7.468,0,5.277,5.277,0,0,0-.133,7.322l7.412,7.426a.476.476,0,0,0,.673,0Z' transform='translate(634.287 -721.234)' fill='%23f89900' stroke='%23f89900' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");

            }
        }
        &::before {

            content: '';
            display: inline-block;
            width: 20px;
            height: 18px;
            margin-right: 10px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='19.893' height='18.007' viewBox='0 0 19.893 18.007'%3E%3Cg transform='translate(0.75 0.75)'%3E%3Cpath d='M-617.373,730.175a5.278,5.278,0,0,0-.066-7.395,5.281,5.281,0,0,0-7.469,0c-.077.077-.145.159-.217.24-.051-.055-.1-.113-.15-.166a5.281,5.281,0,0,0-7.468,0,5.277,5.277,0,0,0-.133,7.322l7.412,7.426a.476.476,0,0,0,.673,0Z' transform='translate(634.287 -721.234)' fill='none' stroke='%23f89900' stroke-width='1.5'/%3E%3C/g%3E%3C/svg%3E");
            background-repeat: no-repeat;
            vertical-align: middle;
        }
    }

    .day-card-body {

        height: 160px;

    }

    .day-card-actions {

        align-items : center;

    }

    .day-card-title {

        height: 49px;
        overflow: hidden;
    }

}

.prices {

    font-size: 20px;
    color: rgba(0, 0, 0, .8);

    .price {

        font-weight: bold;
    }

    .regular-price {

        font-weight: normal;
        text-decoration: line-through;
    }
}

.economie-circulaire {

    .thumbnail {

        img{

            width: auto;

        }
    }

    .day-card-body {

        background-color: #064C3E;
        background-image : url('/images/economie-circulaire.jpg');
        background-repeat: no-repeat;
        background-position: left bottom;

        color: #fff;

    }

}
