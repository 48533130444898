@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'global/body.css';
@import 'global/button.css';
@import 'global/utilities.css';
@import 'global/form.css';
@import 'global/pages-cms.css';

@import 'errors/error-404.css';

@import 'header/header.css';
@import 'header/navigation.css';
@import 'footer/footer.css';

@import 'components/search.css';
@import 'components/card.css';
@import 'components/breadcrumbs.css';
@import 'components/roadmap.css';
@import 'components/box-all.css';
@import 'components/box.css';
@import 'components/modal.css';
@import 'components/box-annonce.css';

@import 'pages/global.css';
@import 'pages/home.css';
@import 'pages/recherche.css';
@import 'pages/annonce.css';
@import 'pages/dashboard.css';
@import 'pages/contact.css';
@import 'pages/register.css';
@import 'pages/profil.css';
@import 'pages/qsn.css';


