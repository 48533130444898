.qsn {

    #top-header {

        height: 385px;
        min-height: unset;
        background-color : #000;
        padding-top: 100px;
        /* padding-top: 139px ; */
        background-image: var(--bg-img);
        background-position: center;

        @screen md {
            height: 350px;
        }
        @screen lg {
            padding-top: 139px;
            min-height: 355px;
        }

        h1 {

            color: #fff;
            margin-bottom: 0;
        }

        &::before {
            background-color: rgba(0,0,0,0.40);
        }

        .subtitle {

            text-align: center;
            padding-bottom: 40px;
            width: 400px;
            max-width: calc(100% - 30px);
            margin: auto;
            /* width: 350px; */
            z-index: 1;
            @apply font-medium;
            @apply text-white;
            @apply text-[20px];

            @screen lg {

                width: 792px;

            }

        }
    }

    .content {

        color: #9A9A9A;
        font-size: 15px;

        .box-content:last-child {

            margin-bottom : 0;

        }

        .box-content {

            @apply grid lg:mb-16 grid-cols-1 lg:grid-cols-2 lg:gap-x-16;

            .img-content {
                order: 2;

                @screen lg {
                    order: 1;
                }
            }
            .text-content {
                order: 1;

                @screen lg {
                    order: 2;
                }
            }

        }

        h2 {

            font-size: 20px;
            margin-bottom: 10px;
            font-weight: 500;
            color: #000;
        }

        p {

            margin-bottom: 26px;

        }

        .text-content {

            display: flex;
            flex-direction: column;
            justify-content: center;
            max-width: 100%;

            @screen lg {
                /* width: 500px; */
            }
        }

        .img-content {

            /* display: none; */
            margin-bottom: 16px;

            @screen lg {

                margin-bottom: 0;
                display: block;
                /* width : 520px; */
                width: 100%;

            }

        }
        .iframe-container {
            position: relative;
            overflow: hidden;
            width: 100%;
            padding-top: 56.25%;

            .responsive-iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
              }
        }

        #middle-content {

            border-radius: 3px;
            margin-bottom: 26px;

            @screen lg {
                /* background-image: url('/images/bg-middle-qsn.png'); */
                background-position: top 35px left 35px;
                background-repeat: no-repeat;
                background-size: 55px;
                margin-bottom: 4rem;
            }

            background-color: rgba(150, 224,175, 0.30);
            color: #214B3F;

            h2 {

                font-size: 20px;
                color: #214B3F;
                font-weight: 500;

            }

            .text-content {

                padding: 15px;
                margin-top: 12px;
                width: 100%;

                @screen lg {
                    /* padding:0; */

                    justify-content: flex-start;
                    padding-left: 35px;
                    padding-bottom: 45px;
                    padding-top: 35px;
                }
                img {
                    height: 55px;
                    width: 55px;
                    margin-bottom: 10px;
                }
            }

        }
    }

}
