/* Webfont: Thunder-BoldLC */
@font-face {
    font-family: 'Thunder';
    src: url('/fonts/Thunder-BoldLC.woff2') format('woff2'), /* Modern Browsers */
         url('/fonts/Thunder-BoldLC.otf') format('opentype'); /* Safari, Android, iOS */
             font-style: normal;
    font-weight: bold;
    text-rendering: optimizeLegibility;
}

body {

    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: #000;
    /* accent-color: #96E0AF; */
    /* outline: 1px solid #96E0AF; */
}
