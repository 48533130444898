#box-card {
    width: 100%;
    color: #9A9A9A;

    @screen md {
        width: 600px;
    }

    input[type='checkbox'] {

        &:checked::before {
            background-color:#F89900;
        }

        &::before {
            border: 2px solid #F89900;
        }

    }

    input[type='text'],
    input[type='number'],
    input[type='email'],
    input[type='password'] {
        padding-top: 15px;
        padding-bottom: 15px;
        height: 28px;
        border-bottom: 1px solid rgba(188, 188, 188, .1);
        position: relative;

        &::placeholder {
            color: #fff;
        }
    }
    textarea {
        border: 1.5px solid #9A9A9A;
        border-radius: 4px;
        padding: 7px 11px;

        resize: none;
        font-size: 12px;
        line-height: 17px;
    }

    input[type='text']:focus-within ~ label,
    input[type='number']:focus-within ~ label,
    input[type='email']:focus-within ~ label,
    input[type='password']:focus-within ~ label,
    input[type='password']:not(:placeholder-shown) ~ label,
    input[type='email']:not(:placeholder-shown) ~ label,
    input[type='text']:not(:placeholder-shown) ~ label,
    input[type='number']:not(:placeholder-shown) ~ label {

        @apply text-black transform scale-90 -translate-y-5;
    }

    /* input:not(:focus-within) ~ label {
        @apply transform duration-300 scale-100;
    } */

    label {

        color : #9A9A9A;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    .disclosure {

        font-size: 12px;
        margin-top: 5px;
        margin-bottom: 39px;
        color: #9A9A9A;
    }


    .btn {

        height: 37px;

    }

    input,
    label {
        font-size: 14px;
    }

    [type="checkbox"] ~ label {
        font-size: 12px;
        line-height: 17px;
        display: inline-block;
        width: calc(100% - 30px);
        vertical-align: text-top;
        margin-bottom: 20px;
    }
    input[required] ~label::after {
        content: "*";
        display: inline-block;
        vertical-align: text-bottom;
    }

    textarea + label + .error {
        position: absolute;
        bottom: 20px;
        font-size: 12px;
    }
}
